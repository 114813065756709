import { Helmet } from "react-helmet"

import CountView from "../components/CountView"

import banner from '../assets/pride/banniere-marche-des-fiertes-amiens-2024-samedi-29-juin.png'
import planVillageAssociatif from '../assets/pride/village-associatif-plan-pride-amiens.png'
import itineraire from '../assets/pride/itineraire-pride-amiens-2024.png'
import cortege from '../assets/pride/plan-cortège-marche-des-fiertes-amiens.png'

export default function PrideInfo2023() {
    return (
        <>
            <Helmet>
                <title>6ème Marche des Fiertés d'Amiens | 29 juin 2024</title>
                <meta name="description" content="La Pride d'Amiens 2024 sera le samedi 29 juin, venez défiler dans les rues d'Amiens à nos côtés !" />
            </Helmet>

            <section className="row justify-content-center pt-2 blockPride4 pb-2">
                <div className="col-12 col-lg-8 pt-4">
                    <h1 className="text-center mb-2">6ème Marche des Fiertés d'Amiens</h1>
                    <h2 className="text-center mb-0">Samedi 29 juin 2024</h2>
                </div>
            </section>

            <section className="row justify-content-center pt-2 blockPride4 pt-md-0">
                <div className="col-12 col-lg-8 mb-3">
                    <img src={banner} alt="Bannière de la Marche des Fiertés d'Amiens 2024" className="img-fluid w-100"/>
                    <p className="thasadith mt-5" style={{fontWeight:"bold"}}>
                        La 6ème Pride d'Amiens se déroulera le samedi 29 juin 2024 ! Encore une fois cette année, un village associatif sera installé sur la place de la Maison de la Culture afin de mettre en valeur les associations du territoire qui luttent contre les discriminations, mais aussi des associations féministes, de prévention en santé et de défense des droits humains ! N'hésitez pas à suivre les réseaux sociaux de Flash Our True Colors et de FiertésAmiens pour avoir les prochaines informations sur la Pride à Amiens en 2024 !
                    </p>
                </div>
            </section>

            <section className="row justify-content-center blockPride4 pt-3">
                <div className="col-12 col-lg-8">
                    <h2 className="text-center mb-4 pt-2">Programme de la 6ème Marche des Fiertés d'Amiens</h2>
                    <ul className="pl-0">
                        <li className="mb-2 thasadith"><i className="fas fa-unlock pr-3"></i>11h00 - Ouverture du village associatif</li>
                        <li className="mb-2 thasadith"><i className="fas fa-hiking pr-4"></i>13h00 - Début des prises de paroles puis départ de la déambulation</li>
                        <li className="mb-2 thasadith"><i className="fas fa-microphone pr-4"></i>16h30 - Retour de la déambulation et musique</li>
                        <li className="thasadith"><i className="fas fa-moon pr-4"></i>18h30 - Fermeture du village associatif</li>
                    </ul>
                </div>
            </section>

            <section className="row justify-content-center blockPride4 pt-5 pb-5 pb-md-4">
                <div className="col-12 col-lg-8">
                    <h2 className="text-center mb-2">Plan du village associatif</h2>
                    <div className="row px-3 px-md-0 pt-3">
                        <div className="col-12 col-xl-6 pl-md-0 pb-2">
                            <img src={planVillageAssociatif} alt="Plan du village associatif de la 6ème Marche des Fiertés d'Amiens" className="img-fluid pb-md-5 pl-md-5 pr-md-5"/>
                        </div>
                        <div className="col-6 col-xl-3 d-flex flex-column">
                            <ol className="mb-0 pl-0 thasadith">
                                <li className="organismName"><h3 className="subTitleAV">Associations LGBTQIA+</h3></li>
                                <li className="organismName"><strong>1</strong> - Vente associative</li>
                                <li className="organismName"><strong>2</strong> - Flash Our True Colors</li>
                                <li className="organismName"><strong>3</strong> - D&J Arc-en-Ciel</li>
                                <li className="organismName"><strong>4</strong> - Fédération Sportive LGBT+</li>
                                <li className="organismName"><strong>5</strong> - Divergenre</li>
                                <li className="organismName"><strong>6</strong> - Fiertés Pas-de-Calais</li>
                                <li className="organismName"><strong>7</strong> - SOS homophobie Picardie</li>
                            </ol>
                            <ol className="mb-0 pl-0 thasadith">
                                <li className="organismName"><h3 className="subTitleAV">Associations féministes</h3></li>
                                <li className="organismName"><strong>8</strong> - Planning Familial 80</li>
                                <li className="organismName"><strong>9</strong> - Nous Toutes 80</li>
                                <li className="organismName"><strong>10</strong> - Sang Tabou</li>
                                <li className="organismName"><strong>13</strong> - AGENA Nina & Simones</li>
                            </ol>
                        </div>
                        <div className="col-6 col-xl-3 d-flex flex-column">
                            <ol className="mb-0 pl-0 thasadith">
                                <li className="organismName"><h3 className="subTitleAV">Droits Humains</h3></li>
                                <li className="organismName"><strong>11</strong> - Amnesty International</li>
                                <li className="organismName"><strong>12</strong> - Ligue des droits de l'homme</li>
                            </ol>
                            <ol className="mb-0 pl-0 thasadith">
                                <li className="organismName"><h3 className="subTitleAV">Prévention Santé</h3></li>
                                <li className="organismName"><strong>14</strong> - Service de santé étudiante UPJV</li>
                                <li className="organismName"><strong>15</strong> - ENIPSE / CIDFF80 / SexoSafe / Le MAIL</li>
                            </ol>
                            <ol className="mb-0 pl-0 thasadith">
                                <li className="organismName"><h3 className="subTitleAV">Syndicats</h3></li>
                                <li className="organismName"><strong>16</strong> - CGT Somme</li>
                                <li className="organismName"><strong>17</strong> - FSU 80</li>
                                <li className="organismName"><strong>18</strong> - CFDT Somme</li>
                                <li className="organismName"><strong>19</strong> - Solidaires 80</li>
                                <li className="organismName"><strong>20</strong> - Union Etudiante Picarde</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="row justify-content-center blockPride4 pt-2 pb-4 pb-md-5">
                <div className="col-12 col-lg-8">
                    <h2 className="text-center mb-4">Organisation des cortèges</h2>
                    <div className="row px-3 px-md-0">
                        <div className="col-12 col-md-6 pt-3 pl-md-0">
                            <img src={cortege} alt="Organisation des cortèges dans la Marche des Fiertés d'Amiens 2024" className="img-fluid pb-md-5 pl-md-5 pr-md-5"/>
                        </div>
                        <div className="col-12 col-md-6 d-flex flex-column justify-content-center pt-3">
                            1 : char de l'association Flash Our True Colors<br/>
                            2 : char du syndicat CFDT Somme<br/>
                            3 : camionnette de l'inter-orga<br/>
                            4 : voiture du cortège calme<br/>
                            5 : cortège calme
                        </div>
                    </div>
                </div>
            </section>

            <section className="row justify-content-center blockPride4 pt-3 pb-4 pb-md-5">
                <div className="col-12 col-lg-8">
                    <h2 className="text-center mb-4">Itinéraire</h2>
                    <div className="row px-3 px-md-0">
                        <div className="col-12 col-md-8 pt-3 pl-md-0">
                            <img src={itineraire} alt="Plan du village associatif de la 6ème Marche des Fiertés d'Amiens" className="img-fluid pb-md-5 pl-md-5 pr-md-5"/>
                        </div>
                        <div className="col-12 col-md-4 d-flex flex-column justify-content-center pt-3">
                            <ol className="mb-0 pl-0 pl-sm-3 pl-lg-5 thasadith">
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Départ Place Léon Gontier</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Rue de la 2eme division Blindée</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Rue de Beauvais</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Rue Dumeril</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Rue des Jacobins</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Rue Allart</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Rue Victor Hugo</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Place Saint Michel</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Rue Adéodat Lefevre</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Rue des Augustins</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Place Parmentier</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Rue Vanmarcke</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Rue des Francs Mûriers</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Place Vogel</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Rue du Général Leclerc</li>
                                <li className="organismName"><i className="fas fa-map-marker-alt mr-2"></i> Arrivée Place Léon Gontier</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}