import { Helmet } from "react-helmet"
import { EventsCard2023 } from "../components/EventCard"
import { programmation2023 } from "../programmation"
import affiche from '../assets/festival/2023/logo-festival-idahot-lgbt-amiens.png'

export default function FestivalInfo2023() {
    return (
        <>
            <Helmet>
                <title>Festival Hauts en Couleur 2023</title>
                <meta name="description" content="Le programme du Festival IDAHOT d'Amiens qui s'appellera le Festival Hauts en Couleur est disponible, nous avons hâte de vous retrouver lors de ce festival !"/>
            </Helmet>

            <section className="row justify-content-center blueOnSaffron pt-3">
                <div className="row col-11 col-lg-8">
                    <div className="col-12 col-xl-6 p-4">
                        <h1 className="text-center mb-2">Festival Hauts en Couleur (IDAHOT)</h1>
                        <h2 className="text-center mb-3">15 mai au 20 mai 2023</h2>
                        <img src={affiche} className="img-fluid" alt="Logo du festival Hauts en Couleur d'Amiens"/>
                    </div>
                    <div className="col-12 col-xl-6 p-4">
                        <p className="thasadith">
                            Le festival Hauts⸱en⸱Couleur a pour but de lutter contre les discriminations faites aux personnes LGBTQIA+ (Lesbienne, Gay, Bi⸱e, Trans, Queer, Intersexe, Asexuel⸱le⸱s/Aromatiques/Agenre et tou⸱te⸱s les autres) du 15 au 20 mai 2023.
                            <br/><br/>Il est organisé sur Amiens, sous différents noms depuis plus de 10 ans. Cet évènement rend hommage au 17 mai, la Journée Internationale de Lutte Contre l’Homophobie, la Transphobie et la Biphobie, appelée aussi IDAHOT en anglais (International Day Against Homophobia, Transphobia and Biphobia).
                            <br/><br/>Nos associations luttent contre les discriminations et œuvrent pour la défense des droits des personnes LGBTQIA+ à Amiens et ses alentours. Nous défendons cette année encore un festival à la programmation LGBTQIA+ afin de valoriser et revendiquer notre place dans le tissu social et culturel.  
                            <br/><br/>Le festival est ouvert à tou⸱te⸱s. L'objectif est de rassembler l'ensemble des citoyen⸱ne⸱s autour des luttes contre les LGBTQIAphobies car nous sommes tou⸱te⸱s concerné⸱e⸱s.
                        </p>
                    </div>
                </div>
            </section>
            <section className="row justify-content-center pt-5 pb-4">
                <div className="col-11 col-md-10">
                    <h2 className="text-center">Programme</h2>
                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 pb-4">
                        <div className="col d-flex justify-content-center pt-2">
                            <i className="fas fa-euro-sign fa-lg mt-1 mr-3"></i>
                            <span>Payant</span>
                        </div>
                        <div className="col d-flex justify-content-center pt-1">
                            <i className="fas fa-user-edit fa-lg mt-1 mr-3"></i>
                            <span>Sur inscription</span>
                        </div>
                    </div>
                    {programmation2023.map((day, index) => <EventsCard2023 data={day} key={index}/>)}
                </div>
            </section>
        </>
    )
}